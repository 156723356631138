import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import success from '../assets/images/success.svg';
import Button from '../ui/widgets/Button/Button';

const Container = styled.div`
  height: 150px;
  width: 270px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: calc(50dvh - 100px) auto;
  text-align: center;
  color: var(--color-green);
  font-size: 14px;
  padding: 14px;

  img {
    margin-bottom: 10px;
  }
`;

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: var(--border-radius);
  background-color: ${({ theme }) => theme.background};
  padding: 25px 20px 20px 20px;

  p {
    white-space: pre-wrap;
    margin-bottom: 15px;
  }
`;

interface Props {
  onRedirect: () => void;
}

const Success: React.FC<Props> = ({ onRedirect }) => {
  useEffect(() => {
    const timer = setTimeout(onRedirect, 5000);
    return () => clearTimeout(timer);
  }, [onRedirect]);

  return (
    <Container>
      <Box>
        <img src={success} alt='success' width={40} height={40} />
        <p>Ваш платеж будет зачислен в&nbsp;течение нескольких минут</p>
        <Button style={{ width: '226px' }} variant='success'>
          Ok
        </Button>
      </Box>
    </Container>
  );
};

export default Success;
