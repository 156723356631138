import { FC } from 'react';
import styled from 'styled-components';
import alert from '../assets/images/alert.svg';
import sbp from '../assets/images/sbp.png';
import Button from '../ui/widgets/Button/Button';
import Field from '../ui/widgets/Field/Field';
import card from '../assets/images/card.svg';
import crypto from '../assets/images/crypto.svg';

const Container = styled.div`
  height: 100vh;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  border-radius: var(--border-radius);
  padding: 16px;
`;

const HeaderBox = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  background-color: ${({ theme }) => theme.background};
  padding: 25px 20px 20px 20px;
  border-radius: var(--border-radius);
`;

const SubTitle = styled.div`
  font-size: 9px;
  margin-bottom: 14px;
`;

const ErrorText = styled.p`
  color: var(--color-red);
  font-size: 14px;
  font-weight: 500;
`;

const FieldGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

interface CancellationProps {
  merchantName?: string;
  merchantImage?: string;
  currency?: string;
  ticketId?: string;
  paymentMethod?: string;
  paymentMethodImage?: string;
  amount?: number;
  onConfirm: () => void;
}

const Cancellation: FC<CancellationProps> = ({
  amount,
  onConfirm,
  paymentMethod,
  ticketId,
  currency = '₽',
}) => {
  const getPaymentMethodInfo = (paymentMethod: string | undefined) => {
    switch (paymentMethod) {
      case 'MC/VISA/MIR P2P RUB':
        return { img: card, text: 'По номеру счёта' };
      case 'SBP P2P RUB':
        return { img: sbp, text: 'Пополнение через СБП' };
      case 'CRYPTO':
        return { img: crypto, text: 'Криптовалюта' };
      case 'A2A RUB':
        return { img: card, text: 'По номеру счёта' };
      default:
        break;
    }
  };

  return (
    <Container>
      <HeaderBox>
        <img src={alert} alt='error' />
        <SubTitle>ID тикета: {ticketId}</SubTitle>
        <FieldGroup>
          <Field
            text={`${amount}.00 ${currency || ''}`}
            position='center'
            fontSize='20px'
            borderColor='#E53535'
          />
          <Field
            text={getPaymentMethodInfo(paymentMethod)?.text}
            image={getPaymentMethodInfo(paymentMethod)?.img}
            position='center'
            borderColor='#E53535'
          />
          <ErrorText>
            Не удалось обработать ваш платеж Пожалуйста, попробуйте еще раз
          </ErrorText>
          <Button
            variant='error'
            style={{ maxWidth: '100%', fontSize: '14px', fontWeight: 600 }}
            onClick={onConfirm}
          >
            Ок
          </Button>
        </FieldGroup>
      </HeaderBox>
    </Container>
  );
};

export default Cancellation;
